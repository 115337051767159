export const EVENTS_NAMES = {
    VEHICLE_STATUS_UPDATED: "vehicle_status_updated"
}
class EventBus {

    #eventsByKey = new Map()

    execute(key, ...params) {
        const events = this.#eventsByKey.get(key) ?? {}
        for (const event of Object.values(events)) {
            event(...params)
        }
    }

    addEvent(key,callBackKey, callback) {
        const events = this.#eventsByKey.get(key) ?? {}
        events[callBackKey] = callback
        this.#eventsByKey.set(key, events)
    }
}

export const eventBus = new EventBus()
